import React from 'react';
import { Router } from '@reach/router';
import { Header } from 'semantic-ui-react';
import Layout from '../components/layout';
import Helmet from '../components/Helmet';
import * as DefaultTemplate from '../components/ContentPages/DefaultTemplate';
import UnsubscribeFunnel from '../components/Funnel/UnsubscribeFunnel';
import { Lattice } from '../components/Lattice';

// Due to how Reacts context API works, we can have another Lattice component that feeds
// child components and maintains its own state in isolation from the parent Lattice that
// sits at the root of the component tree. Context consumers reach for the nearest parent
// provider.
const UnsubscribePage = props => (
    <Layout
        showDisclosures={false}
    >
        <Helmet
            title="Email Unsubscribe"
            description="Opt out of DebtConsolidation emails."
            robots="noindex, follow"
        />
        <DefaultTemplate.Wrapper>
            <DefaultTemplate.Body>
                <Header as="h1" size="large">
                    Email Unsubscribe
                </Header>
                <Lattice>
                    <Router primary={false}>
                        <UnsubscribeFunnel path="/email-opt-out/*" />
                    </Router>
                </Lattice>
            </DefaultTemplate.Body>
        </DefaultTemplate.Wrapper>
    </Layout>
);

export default UnsubscribePage;

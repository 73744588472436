import React from 'react';
import {
    Button, Dimmer, Form, FormGroup, FormField, Grid, Header, Loader, Message,
} from 'semantic-ui-react';
import {
    LatticeCarousel, LatticeSlide, useLatticeContext,
} from '../Lattice';
import * as validations from '../YupSchema';
import { Email } from '../DCFields';
import { usePegasusConduit } from '../PegasusConduit';

const UnsubscribeFunnel = (props) => {
    const { navigate, location } = props;
    const step = location.hash.substring(1) || 'unsubscribe';
    const {
        unsubscribe,
    } = usePegasusConduit();

    const {
        validateWithYup, values, setIsValidating, isSubmitting, setIsSubmitting,
    } = useLatticeContext();

    const validateAndAdvance = async (schema) => {
        try {
            const valid = await validateWithYup(schema);
            if (valid) return true;
        } catch (e) {
            console.log('Failed to validate', e);
        }
        return false;
    };

    return (
        <LatticeCarousel navigate={navigate} step={step} routingMode="hash">
            <LatticeSlide stepId="unsubscribe">
                {({ setStep }) => (
                    <Dimmer.Dimmable blurring dimmed={isSubmitting}>
                        <Dimmer inverted active={isSubmitting}>
                            <Grid columns={1}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Loader
                                            active
                                            size="small"
                                            inline="centered"
                                            content={<Header>Submitting&hellip;</Header>}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Dimmer>
                        <p>
                            Enter your email address below to be unsubscribed from all future DebtConsolidation.com
                            emails.
                        </p>
                        <Form size="small">
                            <FormGroup>
                                <FormField width={8}>
                                    <Email />
                                </FormField>
                            </FormGroup>
                        </Form>
                        <br />
                        <Button.Group>
                            <Button
                                icon="lock"
                                labelPosition="left"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                color="green"
                                content="Submit Now"
                                type="button"
                                onClick={async (e) => {
                                    setIsValidating(true);
                                    const valid = await validateAndAdvance(validations.emailSchema);
                                    try {
                                        if (valid) {
                                            setIsSubmitting(true);
                                            const result = await unsubscribe(values.email);
                                            if (result.success) {
                                                setStep('success');
                                            } else {
                                                setStep('error');
                                            }
                                            setIsSubmitting(false);
                                        }
                                    } catch (e) {
                                        setIsSubmitting(false);
                                    }
                                }}
                            />
                        </Button.Group>
                    </Dimmer.Dimmable>
                )}
            </LatticeSlide>
            <LatticeSlide stepId="success" hidden>
                {({ setStep }) => (
                    <Message positive>
                        <Message.Header>Success!</Message.Header>
                        You have been opted out. Please allow up to 10 business days to be fully removed from future
                        emails.
                    </Message>
                )}
            </LatticeSlide>
            <LatticeSlide stepId="error" hidden>
                {({ setStep }) => (
                    <Message negative>
                        <Message.Header>There was an issue submitting your information.</Message.Header>
                        There was a problem with your request. Please email
                        {' '}
                        <a href="mailto:customerservice@debtconsolidation.com?subject=Unsubscribe">
                        customerservice@debtconsolidation.com
                        </a>
                        {' '}
                        to unsubscribe. Please include the word "Unsubscribe" in the email subject.
                    </Message>
                )}
            </LatticeSlide>
        </LatticeCarousel>
    );
};

export default UnsubscribeFunnel;

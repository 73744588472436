import React from 'react';
import { Input, Message } from 'semantic-ui-react';
import { LatticeField, LatticeError } from '../Lattice';

const Email = () => (
    <LatticeError alwaysRender fieldName="email">
        {error => (
            <>
                <label className={error ? 'error' : null} htmlFor="email">Email</label>
                <LatticeField component={Input} type="email" name="email" id="email" />
                {error && (
                    <Message
                        error
                        visible
                        size="mini"
                        attached="bottom"
                        icon="warning circle"
                        content={error}
                    />
                )}
            </>
        )}
    </LatticeError>
);

export default Email;

import * as yup from 'yup';

const masterSchema = yup.object().shape({
    nameFirst: yup.string().required('First name is required'),
    nameLast: yup.string().required('Last name is required'),
    email: yup
        .string()
        .email('Please use a valid email')
        .required('Email is required'),
    emailConfirm: yup
        .string()
        .email('Please use a valid email')
        .oneOf([yup.ref('email'), null], 'Emails must match')
        .required('Please confirm email'),
    'homeAddress.zip': yup
        .string()
        .required('Zip code is required')
        .max(5, 'Please use a valid zip code'),
    'homeAddress.streetAddress': yup.string().required('Address is required'),
    'homeAddress.state': yup.string().required('State is required'),
    'legalCertification.id': yup.bool().required(),
    'residenceStatus.id': yup.string().required('Please choose a state'),
    'creditRating.id': yup.string().required('Please choose a credit rating option'),
    'employmentInfo.receiveIncome': yup.string().required('Choose income type'),
    'employmentInfo.incomeMonthly': yup.string().required('Please enter monthly income'),
    'employmentInfo.payFrequency.id': yup.string().required('Please enter pay frequency'),
    'employmentInfo.incomeSourceComposite.id': yup.string().required('Please enter monthly income'),
    military: yup.string().required('Required'),
    primaryPhone: yup
        .string()
        .length(12, 'Phone must be 10 characters')
        .matches(/\d\d\d-\d\d\d-\d\d\d\d/, 'Please complete your phone number')
        .required('Phone number is required'),
    'smsOptIn.id': yup.string().required(),
    'totalDebt.id': yup.string().required(),
    'loanReason.id': yup.string().required(),
    ssn: yup.string().required(),
    dateOfBirth: yup.string().required(),
});

const aboutSchema = yup.object().shape({
    nameFirst: yup.string().required('First name is required'),
    nameLast: yup.string().required('Last name is required'),
    email: yup
        .string()
        .email('Please use a valid email')
        .required('Email is required'),
    emailConfirm: yup
        .string()
        .email('Please use a valid email')
        .oneOf([yup.ref('email'), null], 'Emails must match')
        .required('Please confirm email'),
    'homeAddress.zip': yup
        .string()
        .required('Zip code is required')
        .max(5, 'Please use a valid zip code'),
    'legalCertification.id': yup.bool().required(),
});

const contactInfoSchema = yup.object().shape({
    nameFirst: yup.string().required('First name is required'),
    nameLast: yup.string().required('Last name is required'),
    email: yup
        .string()
        .email('Please use a valid email')
        .required('Email is required'),
    primaryPhone: yup
        .string()
        .length(12, 'Phone must be 10 characters')
        .matches(/\d\d\d-\d\d\d-\d\d\d\d/, 'Please complete your phone number')
        .required('Phone is required'),
    'homeAddress.city': yup
        .string()
        .required('City is required'),
    'homeAddress.state': yup
        .string()
        .required('State is required'),
    'homeAddress.zip': yup
        .string()
        .required('Zip code is required')
        .max(5, 'Please use a valid zip code'),
    privacy_policy_acknowledge: yup.bool().required('This is required'),
});

const stateSchema = yup.object().shape({
    'homeAddress.state': yup.string().required('Please enter your state'),
});

const debtInfoSchema = yup.object().shape({
    'totalDebt.id': yup.string().required(),
    'loanReason.id': yup.string().required(),
});

const totalDebtSchema = yup.object().shape({
    'totalDebt.id': yup.string().required(''),
});

const emailSchema = yup.object().shape({
    email: yup
        .string()
        .email('Please use a valid email')
        .required('Email is required'),
});

const phoneSchema = yup.object().shape({
    primaryPhone: yup
        .string()
        .length(12, 'Phone must be 10 characters')
        .matches(/\d\d\d-\d\d\d-\d\d\d\d/, 'Please complete your phone number')
        .required('Please enter your phone number'),
});

const incomeInfoSchema = yup.object().shape({
    'employmentInfo.incomeMonthly': yup.string().required('Please enter monthly income'),
    'employmentInfo.incomeSourceComposite.id': yup.string().required('Fix this error mapping'),
});

const receiveIncomeSchema = yup.object().shape({
    'employmentInfo.receiveIncome': yup.string().required('Choose income type'),
});

const militarySchema = yup.object().shape({
    military: yup.bool().required('Required'),
});

const creditRatingSchema = yup.object().shape({
    'creditRating.id': yup.string().required('Please choose a credit rating option'),
});

const addressInfoSchema = yup.object().shape({
    'homeAddress.streetAddress': yup.string().required('Address is required'),
    'homeAddress.state': yup.string().required('State is required'),
    'homeAddress.city': yup.string().required('City is required'),
    'residenceStatus.id': yup.string().required('Do you rent or own your home?'),
});

const payFrequencySchema = yup.object().shape({
    'employmentInfo.payFrequency.id': yup.string().required('Please enter pay frequency'),
});

const securityInfoSchema = yup.object().shape({
    ssn: yup.string().required(),
    dateOfBirth: yup.string().required(),
});

const fullDebtSettlementSchema = totalDebtSchema.concat(contactInfoSchema);

export {
    aboutSchema,
    masterSchema,
    stateSchema,
    debtInfoSchema,
    totalDebtSchema,
    phoneSchema,
    incomeInfoSchema,
    receiveIncomeSchema,
    militarySchema,
    creditRatingSchema,
    addressInfoSchema,
    payFrequencySchema,
    securityInfoSchema,
    contactInfoSchema,
    emailSchema,
    fullDebtSettlementSchema,
};
